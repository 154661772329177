import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AdminPortalGateway from './pages/AdminPortalGateway';
import PrivateRoutes from './components/PrivateRoutes'
import Home from './pages/Home';
import Products from './pages/Products';
import "./App.css";
import AddNewProduct from './pages/AddNewProduct';
import ProductDisplay from './pages/ProductDisplay';
import EditProductDetails from './pages/EditProductDetails';

function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/' element={<AdminPortalGateway />} />

          <Route element={<PrivateRoutes />}>
            <Route path='/home' element={<Home />} />
            <Route path='/products' element={<Products />} />
            <Route path='/products/:productName' element={<ProductDisplay />} />
            <Route path='/addnewproduct' element={<AddNewProduct />} />
            <Route path='/editproductdetails' element={<EditProductDetails />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
