import React from 'react';
import { Container } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import "../CustomStyles.css";

const Home = () => {

    return (
        <Container fluid>
            <NavigationBar activePath='/home' />
            <div className='main'>
                <p>Home Page</p>
            </div>
            <Footer />
        </Container>
    )
}

export default Home
