import React, { useState } from 'react';
import "./AdminPortal.css";
import LoginModal from '../components/LoginModal';

const AdminPortalGateway = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const openAdminLoginModal = () => {
        openModal();
    };

    const openShop = () => {
        window.open('https://artparadise.madushiranasooriyaportfolio.com/', '_blank');
    }
    return (
        <>
            <div className='admin-portal-gateway'>
                <button onClick={openAdminLoginModal}>ADMIN PORTAL</button>
                <button onClick={openShop}>SHOP</button>
            </div>
            <LoginModal showModal={showModal} closeModal={closeModal} />
        </>

    )
}

export default AdminPortalGateway;
