import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { getCollections } from '../helpers/getData';
import { storage } from '../config/firebase';
import axios from 'axios';
import "./AddNewProduct.css";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const AddNewProduct = () => {
    const MAX_IMAGES = 5;
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [price, setPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [productImage, setProductImage] = useState(null);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedCollectionEnterMethod, setSelectedCollectionEnterMethod] = useState('existing');
    const [collection, setCollection] = useState('')
    const [description, setDescription] = useState('');
    const [specifications, setSpecifications] = useState('');
    const navigate = useNavigate();

    const viewAllProducts = () => {
        navigate('/products');
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const collectionData = await getCollections();
                setCollections(collectionData);
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const name = e.target.name;
        if (name === 'productName') {
            setProductName(e.target.value);
        }
        else if (name === 'productId') {
            setProductId(e.target.value);
        }
        else if (name === 'price') {
            setPrice(e.target.value);
        }
        else if (name === 'salePrice') {
            setSalePrice(e.target.value);
        }
        else if (name === 'collectionEnterMethod') {
            setSelectedCollectionEnterMethod(e.target.value);
            setCollection('');
        }
        else if (name === 'collection' || name === 'newCollection') {
            setCollection(e.target.value);
        }
        else if (name === 'description') {
            setDescription(e.target.value);
        }
        else if (name === 'specifications') {
            setSpecifications(e.target.value);
        }
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setProductImage(file);
    };

    const handleAdditionalImageUpload = (event) => {
        const file = event.target.files[0];
        setAdditionalImages([...additionalImages, file]);
    };

    const handleRemoveImage = (index) => {
        setProductImage(null);
    };

    const handleRemoveAdditionalImage = (index) => {
        const updatedImages = additionalImages.filter((_, i) => i !== index);
        setAdditionalImages(updatedImages);

        const fileInput = document.getElementById(`additionalImage${additionalImages.length}`);
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const addNewProduct = async (e) => {
        e.preventDefault();

        if (productName && productId && price && productImage && collection && description && specifications) {
            try {
                const productImageRef = ref(storage, `products/${productName}_${Date.now()}`);
                await uploadBytes(productImageRef, productImage);
                const productImageUrl = await getDownloadURL(productImageRef);

                let additionalImageUrls = [];
                if (additionalImages.length > 0) {
                    additionalImageUrls = await Promise.all(
                        additionalImages.map(async (image, index) => {
                            const additionalImageRef = ref(storage, `products/${productName}_Additional__${Date.now()}`);
                            await uploadBytes(additionalImageRef, image);
                            return getDownloadURL(additionalImageRef);
                        })
                    );
                }

                const formData = {
                    productName: productName,
                    productId: productId,
                    price: price,
                    salePrice: salePrice || '',
                    productImageUrl: productImageUrl,
                    additionalProductImagesUrls: additionalImageUrls,
                    collection: collection,
                    description: description,
                    specifications: specifications
                };

                const response = await axios.post('https://artparadise.madushiranasooriyaportfolio.com/api/addNewProduct.php', formData);

                if (response.status === 201) {
                    alert('Product Added Successfully!');
                } else {
                    alert('Failed to add product. Please try again.');
                }

                setProductName('');
                setProductId('');
                setPrice('');
                setSalePrice('');
                setProductImage(null);
                setAdditionalImages([]);
                setCollection('');
                setDescription('');
                setSpecifications('');

            } catch (error) {
                console.error('Error adding product:', error);
                alert('Something went wrong. Please try again.');
            }

        } else {
            alert('Please fill in all required fields.');
        }
    }

    return (
        <Container fluid>
            <NavigationBar activePath='/products' />
            <div className='main'>
                <div className='new-product-details'>
                    <p onClick={viewAllProducts}>View All Products</p>
                    <h3>Add New Product</h3>
                    <form onSubmit={addNewProduct}>
                        <div className='row mb-3'>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='productName' className='form-label'>
                                    Product Name
                                </label>
                                <input type='text' className='form-control' id='productName' name='productName'
                                    value={productName} onChange={handleChange} required />
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='productId' className='form-label'>
                                    Product ID
                                </label>
                                <input type='text' className='form-control' id='productId' name='productId'
                                    value={productId} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='price' className='form-label'>
                                    Price (Rs)
                                </label>
                                <input type='text' className='form-control' id='price' name='price' value={price}
                                    onChange={handleChange} required />
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='salePrice' className='form-label'>
                                    Sale Price (Rs)
                                </label>
                                <input type='text' className='form-control' id='salePrice' name='salePrice'
                                    value={salePrice} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <label htmlFor='productImage' className='form-label'>
                                    Product Image
                                </label>
                                <div className='image-upload-container'>
                                    {productImage ? (
                                        <div className='image-preview'>
                                            <img src={URL.createObjectURL(productImage)} alt='Product' />
                                            <button className='remove-image-button' onClick={handleRemoveImage}>
                                                <span>&times;</span>
                                            </button>
                                        </div>
                                    ) : (
                                        <label htmlFor='productImage' className='upload-icon'>
                                            <FontAwesomeIcon icon={faCamera} size='lg' />
                                            <input type='file' id='productImage' name='productImage' className='hidden'
                                                accept='image/*' onChange={handleImageUpload} required />
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <label htmlFor='additionalImages' className='form-label'>
                                    Additional Images (Optional)
                                </label>
                                <div className='row'>
                                    {additionalImages.map((image, index) => (
                                        <div className='col-4 col-md-2 mb-3' key={index}>
                                            <div className='image-upload-container'>
                                                <div className='image-preview'>
                                                    <img src={URL.createObjectURL(image)} alt={`Product ${index + 1}`} />
                                                    <button className='remove-image-button' onClick={() => handleRemoveAdditionalImage(index)}>
                                                        <span>&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {additionalImages.length < MAX_IMAGES &&
                                        <div className='image-upload-container'>
                                            <label className='upload-icon' htmlFor={`additionalImage${additionalImages.length}`}>
                                                <FontAwesomeIcon icon={faCamera} size='lg' />
                                            </label>
                                            <input type='file' id={`additionalImage${additionalImages.length}`} name={`additionalImage${additionalImages.length}`} className='hidden'
                                                accept='image/*' onChange={handleAdditionalImageUpload} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <label htmlFor='collection' className='form-label'>
                                    Collection Name
                                </label>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' name='collectionEnterMethod'
                                        id='existingName' value='existing' checked={selectedCollectionEnterMethod === 'existing'}
                                        onChange={handleChange} />
                                    <label htmlFor='existingName' className='form-check-label'>
                                        Existing Collection
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' name='collectionEnterMethod'
                                        id='newName' value='new' checked={selectedCollectionEnterMethod === 'new'}
                                        onChange={handleChange} />
                                    <label htmlFor='newName' className='form-check-label'>
                                        New Collection
                                    </label>
                                </div>
                            </div>
                            {selectedCollectionEnterMethod === 'existing' && (
                                <div className='col-12 mb-3'>
                                    <select id='collection' name='collection' className='form-select' value={collection} onChange={handleChange}>
                                        <option value=''>--Select Collection--</option>
                                        {collections && collections.map((collectionItem, index) => (
                                            <option key={index} value={collectionItem.collection}>{collectionItem.collection}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {selectedCollectionEnterMethod === 'new' && (
                                <div className='col-12 col-sm-6 col-md-4 mb-3'>
                                    <input type='text' id='newCollection' name='newCollection' className='form-control'
                                        value={collection} onChange={handleChange} placeholder='Enter Collection Name' />
                                </div>
                            )}

                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 mb-3'>
                                <label htmlFor='description' className='form-label'>
                                    Description
                                </label>
                                <textarea id='description' name='description' className='form-control' rows='5'
                                    value={description} onChange={handleChange} />
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <label htmlFor='specifications' className='form-label'>
                                    Specifications
                                </label>
                                <textarea id='specifications' name='specifications' className='form-control' rows='5'
                                    value={specifications} onChange={handleChange} />
                            </div>
                        </div>

                        <button type='submit' className='add-new-button'>
                            Add Product
                        </button>
                    </form>
                </div>
            </div >


            <Footer />
        </Container >

    )
}

export default AddNewProduct
