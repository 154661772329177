import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import Product from '../components/Product';
import { getProducts } from '../helpers/getData';
import "../CustomStyles.css";
import "./Products.css";
import AddNewProductModal from '../components/AddNewProductModal';
import { useNavigate } from 'react-router-dom';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productData = await getProducts();
                setProducts(productData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleAddNewProduct = () => {
        navigate('/addnewproduct');
    }

    return (
        <Container fluid>
            <NavigationBar activePath='/products' />
            <div className='main'>
                <div className='add-new-product'>
                    <button className='add-new-button' onClick={handleAddNewProduct}>
                        Add New Product
                    </button>
                    {/*<button className='add-new-button' onClick={handleShowModal}>
                        Add New Product
                    </button>
                    <AddNewProductModal show={showModal} handleClose={handleCloseModal} />*/}
                </div>
                <div className='products'>
                    <h3>All Products</h3>
                    {loading ? (
                        <div className='text-center mt-5'>
                            <Spinner animation='border' role='status'>
                                <span className='sr-only'>Loading</span>
                            </Spinner>
                        </div>
                    ) : (
                        <div className='row justify-content-center'>
                            {products.length === 0 ? (
                                <p>No data available.</p>
                            ) : (
                                products.map(product => (
                                    <div key={product.productId} className='col-sm-6 col-md-4 col-lg-3'>
                                        <Product data={product} />
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default Products
