import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebase';
import "./LoginModal.css";

const LoginModal = ({ showModal, closeModal }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [validLogin, setValidLogin] = useState(true);
    const [resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [resetPassword, setResetPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                navigate('/home');
            })
            .catch((error) => {
                if (error.code === 'auth/invalid-login-credentials') {
                    setValidLogin(false);
                }
            });
    };

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = () => {
        setResetPassword(true);
    };

    const handleSendResetEmail = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, resetPasswordEmail);
            alert('Password reset email sent. Check your inbox.');
            closeModal();
        } catch (error) {
            alert('Error sending password reset email: ' + error.message)
        }
    };

    return (
        <div className='login-modal'>
            <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false}>
                <div className='login-form'>
                    <div className='login-box'>
                        <button type="button" className="close" onClick={closeModal}>
                            <span>&times;</span>
                        </button>
                        <div className='login-content'>
                            {resetPassword ? (
                                <div>
                                    <h3>Reset Password</h3>
                                    <form onSubmit={handleSendResetEmail} >
                                        <div className='mb-3'>
                                            <label htmlFor='resetPasswordEmail' className='form-label'>
                                                Email
                                            </label>
                                            <input type='email' className='form-control' id='resetPasswordEmail'
                                                value={resetPasswordEmail} placeholder='name@example.com'
                                                name='resetPasswordEmail' required
                                                onChange={(e) => setResetPasswordEmail(e.target.value)} />
                                        </div>
                                        <div className="d-flex justify-content-center" >
                                            <button type="submit" className="btn-login">
                                                Send Email
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-5">
                                        <span className="link link-login" onClick={() => setResetPassword(false)}>
                                            Go to Log In
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <h3>Admin Login</h3>
                                    <form onSubmit={handleLogin}>
                                        {formSubmitted && !validLogin && <p className='login-error'>Invalid email or password</p>}
                                        <div className='mb-3'>
                                            <label htmlFor="email" className="form-label">
                                                Email
                                            </label>
                                            <input type='email' className='form-control' id='email'
                                                placeholder='name@example.com' name='email' value={email}
                                                onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                        <div className='mb-3'>
                                            <label htmlFor="password" className="form-label">
                                                Password
                                            </label>
                                            <div className='log-password'>
                                                <input type={showPassword ? 'text' : 'password'} className='form-control' id='password'
                                                    placeholder='***************' name='password' value={password}
                                                    onChange={(e) => setPassword(e.target.value)} required />
                                                <button type='button' onClick={handlePasswordToggle}>
                                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn-login">
                                                Login
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <span className="link link-login" onClick={handleForgotPassword}>
                                            Reset Password
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default LoginModal
