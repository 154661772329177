export const getProducts = async () => {
  return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getProducts.php')
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching data:', error);
      return [];
    });
};

export const getCollections = async () => {
  return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getCollectionNames.php')
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching data:', error);
      return [];
    });
};

export const getProductById = async (productId) => {
  return fetch(`https://artparadise.madushiranasooriyaportfolio.com/api/getProductById.php?productId=${productId}`)
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching product data:', error);
      return null;
    });
};

export const getProductImagesByProductId = async (productId) => {
  return fetch(`https://artparadise.madushiranasooriyaportfolio.com/api/getProductImagesByProductId.php?productId=${productId}`)
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching product data:', error);
      return null;
    });
};