import React from 'react';
import { Navbar, Nav, Row, Col, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import logo from '../images/logo.jpeg';
import { SignOut } from 'phosphor-react';
import "./NavigationBar.css";

const NavigationBar = ({ activePath }) => {
    const navigate = useNavigate();

    const userSignOut = () => {
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            alert(error);
        });
    }
    return (
        <>
            <div className="sticky-top">
                {/* Large Screens */}
                <Navbar variant="dark" expand="lg" className="navbar-dark d-none d-lg-block ">
                    <Row className="w-100">
                        <Col xs={2} className="d-flex justify-content-start">
                            <Link to="/home" className="logo-link">
                                <img src={logo} width="70px" height="70px" alt="logo.jpg" />
                                <h4>Art Paradise</h4>
                            </Link>
                        </Col>
                        <Col xs={8} className="d-flex justify-content-center align-items-center">
                            <Navbar expand="lg" className="navbar-dark d-none d-lg-block">
                                <Nav className="d-flex justify-content-center align-items-center">
                                    <Link to="/home" className="nav-link">
                                        <span className={`navbar-link ${activePath === '/home' ? 'active-link' : ''}`}>HOME</span>
                                    </Link>
                                    <Link to="/products" className="nav-link">
                                        <span className={`navbar-link ${activePath === '/products' ? 'active-link' : ''}`}>PRODUCTS</span>
                                    </Link>
                                    {/*<NavDropdown
                                        title={<span className={`navbar-link ${activePath === '/collections' ? 'active-link' : ''}`}>COLLECTIONS</span>}
                                        id="basic-nav-dropdown">
                                        {collections.map((collection, index) => (
                                            <NavDropdown.Item key={index}
                                                href={`/collections/${collection.collection}`} className="dropdown-item" >
                                                {collection.collection}
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                    <Link to="/about" className="nav-link">
                                        <span className={`navbar-link ${activePath === '/about' ? 'active-link' : ''}`}>ABOUT</span>
                                    </Link>
                                    <Link to="/contact" className="nav-link">
                                        <span className={`navbar-link ${activePath === '/contact' ? 'active-link' : ''}`}>CONTACT</span>
                                        </Link>*/}
                                </Nav>
                            </Navbar>
                        </Col>
                        <Col xs={2} className="d-flex justify-content-end align-items-center">
                            <button className='btn-logout' onClick={userSignOut}>
                                <span>Logout</span>
                                <span className="ml-2">
                                    <SignOut size={32} />
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Navbar>

                {/* Small Screens */}
                <Navbar variant="dark" expand="lg" className="navbar-dark d-lg-none d-flex align-items-center">
                    <Row className="w-100">
                        <Col xs={2} className="d-flex justify-content-start align-items-center">
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        </Col>
                        <Col xs={8} className="d-flex justify-content-center">
                            <Link to="/home" className="logo-link">
                                <img src={logo} width="70px" height="70px" alt="logo.jpg" />
                            </Link>
                        </Col>
                        <Col xs={2} className="d-flex justify-content-end align-items-center">
                            <button className='btn-logout' onClick={userSignOut}>
                                <SignOut size={32} />
                            </button>
                        </Col>
                    </Row>
                    <Navbar.Collapse id="basic-navbar-nav" className="navbar-dark">
                        <Nav className="d-flex flex-column align-items-start">
                            <Link to="/home" className="nav-link">
                                <span className={`navbar-link ${activePath === '/home' ? 'active-link' : ''}`}>HOME</span>
                            </Link>
                            <Link to="/products" className="nav-link">
                                <span className={`navbar-link ${activePath === '/products' ? 'active-link' : ''}`}>PRODUCTS</span>
                            </Link>
                            {/*<NavDropdown
                                title={<span className={`navbar-link ${props.activePath === '/collections' ? 'active-link' : ''}`}>COLLECTIONS</span>}
                                id="basic-nav-dropdown">
                                {collections.map((collection, index) => (
                                    <NavDropdown.Item key={index}
                                        href={`/collections/${collection.collection}`} className="dropdown-item" >
                                        {collection.collection}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <Link to="/about" className="nav-link">
                                <span className={`navbar-link ${props.activePath === '/about' ? 'active-link' : ''}`}>ABOUT</span>
                            </Link>
                            <Link to="/contact" className="nav-link">
                                <span className={`navbar-link ${props.activePath === '/contact' ? 'active-link' : ''}`}>CONTACT</span>
                                </Link>*/}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </>
    )
}

export default NavigationBar
