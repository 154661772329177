import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProductById, getCollections, getProductImagesByProductId } from '../helpers/getData';
import { storage } from '../config/firebase';
import { uploadBytes, getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import "./EditProductDetails.css";

const EditProductDetails = () => {
    const MAX_IMAGES = 5;
    const [productName, setProductName] = useState('');
    const [ownerProductId, setOwnerProductId] = useState('');
    const [price, setPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [collections, setCollections] = useState([]);
    const [selectedCollectionEnterMethod, setSelectedCollectionEnterMethod] = useState('existing');
    const [productImageUrl, setProductImageUrl] = useState('');
    const [removedProductImageUrl, setRemovedProductImageUrl] = useState('');
    const [collection, setCollection] = useState('');
    const [description, setDescription] = useState('');
    const [specifications, setSpecifications] = useState('');
    const [additionalImages, setAdditionalImages] = useState([]);
    const [removedAdditionalImages, setRemovedAdditionalImages] = useState([]);
    const [newAdditionalImages, setNewAdditionalImages] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const productId = location.state.productId;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const viewAllProducts = () => {
        navigate('/products');
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const collectionData = await getCollections();
                setCollections(collectionData);
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const productDetails = await getProductById(productId);
                setProductName(productDetails.productName);
                setOwnerProductId(productDetails.ownerProductId);
                setPrice(productDetails.price);
                if (productDetails.salePrice !== null) {
                    setSalePrice(productDetails.salePrice);
                }
                setProductImageUrl(productDetails.productImage);
                setCollection(productDetails.collection);
                setDescription(productDetails.description);
                setSpecifications(productDetails.specifications);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };
        fetchData();
    }, [productId]);

    useEffect(() => {
        const fetchData = async () => {
            const additionalImages = await getProductImagesByProductId(productId)
            setAdditionalImages(additionalImages);
        };
        fetchData();
    }, [productId]);

    const handleChange = (e) => {
        const name = e.target.name;
        if (name === 'productName') {
            setProductName(e.target.value);
        }
        else if (name === 'ownerProductId') {
            setOwnerProductId(e.target.value);
        }
        else if (name === 'price') {
            setPrice(e.target.value);
        }
        else if (name === 'salePrice') {
            setSalePrice(e.target.value);
        }
        else if (name === 'collectionEnterMethod') {
            setSelectedCollectionEnterMethod(e.target.value);
            setCollection('');
        }
        else if (name === 'collection' || name === 'newCollection') {
            setCollection(e.target.value);
        }
        else if (name === 'description') {
            setDescription(e.target.value);
        }
        else if (name === 'specifications') {
            setSpecifications(e.target.value);
        }
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const productImageRef = ref(storage, `products/${productName}_${Date.now()}`);
                await uploadBytes(productImageRef, file);
                const imageUrl = await getDownloadURL(productImageRef);
                setProductImageUrl(imageUrl);
            } catch (error) {
                console.error('Error storing image: ', error);
            }
        }
    };

    const handleRemoveImage = async (e) => {
        e.preventDefault();
        try {
            if (removedProductImageUrl && productImageUrl) {
                const productImageRef = ref(storage, productImageUrl);
                await deleteObject(productImageRef);
                setProductImageUrl('');
            }
            else if (!removedProductImageUrl && productImageUrl) {
                setRemovedProductImageUrl(productImageUrl);
                setProductImageUrl('');
            }
        } catch (error) {
            console.error('Error removing image:', error);
        }
    }


    const handleAdditionalImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewAdditionalImages(prevImages => [...prevImages, file]);
        }
    }

    const handleRemoveAdditionalImage = (e, index) => {
        e.preventDefault();
        const imageToRemove = additionalImages[index];
        setRemovedAdditionalImages(prev => [...prev, imageToRemove]);
        setAdditionalImages(additionalImages.filter((_, idx) => idx !== index));
    }

    const handleRemoveNewAdditionalImage = (e, index) => {
        e.preventDefault();
        setNewAdditionalImages(newAdditionalImages.filter((_, idx) => idx !== index));
    }

    const editProductDetails = async (e) => {
        e.preventDefault();

        if (productName && ownerProductId && price && productImageUrl && collection && description && specifications) {
            try {
                if (removedProductImageUrl && productImageUrl) {
                    const removedProductImageRef = ref(storage, removedProductImageUrl);
                    await deleteObject(removedProductImageRef);
                }

                for (let removedImage of removedAdditionalImages) {
                    try {
                        const imageRef = ref(storage, removedImage.image);
                        await deleteObject(imageRef);
                        await axios.post('https://artparadise.madushiranasooriyaportfolio.com/api/removeProductImageByImageId.php', { imageId: removedImage.imageId });
                    } catch (error) {
                        console.error('Error removing image:', error);
                    }
                }

                let newAdditionalImageUrls = [];
                if (newAdditionalImages.length > 0) {
                    newAdditionalImageUrls = await Promise.all(
                        newAdditionalImages.map(async (image, index) => {
                            const additionalImageRef = ref(storage, `products/${productName}_Additional__${Date.now()}`);
                            await uploadBytes(additionalImageRef, image);
                            return getDownloadURL(additionalImageRef);
                        })
                    );
                }

                const formData = {
                    productId,
                    ownerProductId,
                    productName,
                    price,
                    salePrice,
                    productImageUrl,
                    collection,
                    description,
                    specifications,
                    newAdditionalImageUrls
                };

                console.log('Form data before submission:', formData);

                const response = await axios.post('https://artparadise.madushiranasooriyaportfolio.com/api/editProductDetails.php', formData);
                if (response.status === 200) {
                    alert('Product details updated successfully!');
                    navigate(`/products`);
                } else {
                    alert('Failed to update product details. Please try again.');
                }
            } catch (error) {
                console.error('Error editing product details:', error);
                alert('Something went wrong. Please try again.');
            }
        }

    }

    const handleCancel = async () => {
        if (removedProductImageUrl && productImageUrl) {
            const productImageRef = ref(storage, productImageUrl);
            await deleteObject(productImageRef);
        }
        navigate('/products');
    }

    return (
        <Container fluid>
            <NavigationBar activePath='/products' />
            <div className='main'>
                <div className='edit-product-details'>
                    <p onClick={viewAllProducts}>View All Products</p>
                    <h3>Edit Product Details</h3>
                    <form onSubmit={editProductDetails}>
                        <div className='row mb-3'>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='productName' className='form-label'>
                                    Product Name
                                </label>
                                <input type='text' className='form-control' id='productName' name='productName'
                                    value={productName} onChange={handleChange} required />
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='ownerProductId' className='form-label'>
                                    Product ID
                                </label>
                                <input type='text' className='form-control' id='ownerProductId' name='ownerProductId'
                                    value={ownerProductId} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='price' className='form-label'>
                                    Price (Rs)
                                </label>
                                <input type='text' className='form-control' id='price' name='price' value={price}
                                    onChange={handleChange} required />
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor='salePrice' className='form-label'>
                                    Sale Price (Rs)
                                </label>
                                <input type='text' className='form-control' id='salePrice' name='salePrice'
                                    value={salePrice} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <label htmlFor='productImage' className='form-label'>
                                    Product Image
                                </label>
                                <div className='image-upload-container'>
                                    {productImageUrl ? (
                                        <div className='image-preview'>
                                            <img src={productImageUrl} alt='Product' />
                                            <button className='remove-image-button' onClick={handleRemoveImage}>
                                                <span>&times;</span>
                                            </button>
                                        </div>
                                    ) : (
                                        <label htmlFor='productImage' className='upload-icon'>
                                            <FontAwesomeIcon icon={faCamera} size='lg' />
                                            <input type='file' id='productImage' name='productImage' className='hidden'
                                                accept='image/*' onChange={handleImageUpload} required />
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <label htmlFor='additionalImages' className='form-label'>
                                Additional Images (Optional)
                            </label>
                            <div className='row'>
                                {additionalImages && additionalImages.map((image, index) => (
                                    <div className='col-4 col-md-2 mb-3' key={index}>
                                        <div className='image-upload-container'>
                                            <div className='image-preview'>
                                                <img src={image.image} alt={`Product ${index + 1}`} />
                                                <button className='remove-image-button'
                                                    onClick={(e) => handleRemoveAdditionalImage(e, index, image.imageId, image.image)}>
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {newAdditionalImages && newAdditionalImages.map((image, index) => (
                                    <div className='col-4 col-md-2 mb-3' key={index}>
                                        <div className='image-upload-container'>
                                            <div className='image-preview'>
                                                <img src={URL.createObjectURL(image)} alt={`Product ${index + 1}`} />
                                                <button className='remove-image-button'
                                                    onClick={(e) => handleRemoveNewAdditionalImage(e, index, image)}>
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {newAdditionalImages.length < MAX_IMAGES - additionalImages.length &&
                                    <div className='image-upload-container'>
                                        <label className='upload-icon' htmlFor={`additionalImage${additionalImages.length}`}>
                                            <FontAwesomeIcon icon={faCamera} size='lg' />
                                        </label>
                                        <input type='file' id={`additionalImage${additionalImages.length}`} name={`additionalImage${additionalImages.length}`} className='hidden'
                                            accept='image/*' onChange={handleAdditionalImageUpload} />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-12'>
                                <label htmlFor='collection' className='form-label'>
                                    Collection Name
                                </label>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' name='collectionEnterMethod'
                                        id='existingName' value='existing' checked={selectedCollectionEnterMethod === 'existing'}
                                        onChange={handleChange} />
                                    <label htmlFor='existingName' className='form-check-label'>
                                        Existing Collection
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' name='collectionEnterMethod'
                                        id='newName' value='new' checked={selectedCollectionEnterMethod === 'new'}
                                        onChange={handleChange} />
                                    <label htmlFor='newName' className='form-check-label'>
                                        New Collection
                                    </label>
                                </div>
                            </div>
                            {selectedCollectionEnterMethod === 'existing' && (
                                <div className='col-12 mb-3'>
                                    <select id='collection' name='collection' className='form-select' value={collection} onChange={handleChange}>
                                        <option value=''>--Select Collection--</option>
                                        {collections && collections.map((collectionItem, index) => (
                                            <option key={index} value={collectionItem.collection}>{collectionItem.collection}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {selectedCollectionEnterMethod === 'new' && (
                                <div className='col-12 col-sm-6 col-md-4 mb-3'>
                                    <input type='text' id='newCollection' name='newCollection' className='form-control'
                                        value={collection} onChange={handleChange} placeholder='Enter Collection Name' />
                                </div>
                            )}

                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6 mb-3'>
                                <label htmlFor='description' className='form-label'>
                                    Description
                                </label>
                                <textarea id='description' name='description' className='form-control' rows='5'
                                    value={description} onChange={handleChange} />
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <label htmlFor='specifications' className='form-label'>
                                    Specifications
                                </label>
                                <textarea id='specifications' name='specifications' className='form-control' rows='5'
                                    value={specifications} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='button-group'>
                            <button type='button' className='cancel-button' onClick={handleCancel} >
                                Cancel
                            </button>
                            <button type='submit' className='edit-details-button'>
                                Save
                            </button>
                        </div>
                    </form>

                    <h3>Edit Product Images</h3>
                </div>
            </div >
            <Footer />
        </Container >
    );
}

export default EditProductDetails;
