import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProductById, getProductImagesByProductId } from '../helpers/getData';
import { Container } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import "./ProductDisplay.css";

const ProductDisplay = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const productId = location.state.productId;
    const [product, setProduct] = useState(null);
    const [productImages, setProductImages] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const productData = await getProductById(productId)
            setProduct(productData);
        };

        fetchData();
    }, [productId]);

    useEffect(() => {
        if (product) {
            setSelectedImage(product.productImage);
        }
    }, [product]);

    useEffect(() => {
        const fetchData = async () => {
            const productImages = await getProductImagesByProductId(productId);
            setProductImages(productImages);
        };

        fetchData();
    }, [productId]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    }

    const getSpecifications = (specifications) => {
        return specifications.split(',').filter(specification => specification.trim() !== '');
    };

    const viewAllProducts = () => {
        navigate('/products');
    }

    return (
        <Container fluid>
            <NavigationBar activePath='/products' />
            <div className='main'>
                <div className='row'>
                    {product ?
                        <>
                            <div className='col-12 col-md-6 product-images'>
                                <div className='large-img'>
                                    <img src={selectedImage} alt='Product' />
                                </div>
                                <div className='small-img'>
                                    <img src={product.productImage} alt='Image1'
                                        className={selectedImage === product.productImage ? 'selected' : ''}
                                        onClick={() => handleImageClick(product.productImage)} />
                                    {productImages && productImages.length > 0 ? productImages.map((image) => (
                                        <img src={image.image} alt={`Product ${image.productId}`}
                                            className={selectedImage === image.image ? 'selected' : ''}
                                            onClick={() => handleImageClick(image.image)} />
                                    )) : null}

                                </div>
                            </div>
                            <div className='col-12 col-md-6 product-details'>
                                <h4>{product.productName}</h4>
                                {product.salePrice ?
                                    <p>
                                        <b>Rs.<del>{product.price}</del> {product.salePrice}</b>
                                        &nbsp;&nbsp;&nbsp;<span className='sale'>Save Rs.{product.price - product.salePrice}</span>
                                    </p>
                                    : <p><b>Rs.{product.price}</b></p>
                                }
                                <p>{product.description}</p>
                                <hr></hr>
                                {product.specifications ?
                                    <>
                                        <div className='product-spec'>
                                            <h5>Product Specifications</h5>
                                            <ul>
                                                {getSpecifications(product.specifications).map((specification, index) => (
                                                    <li key={index}>{specification.trim()}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </> : null
                                }
                            </div>
                        </>   : null 
                    }
                </div>
                <button className='view-all-products-button' onClick={viewAllProducts}>
                    All Products
                </button>
            </div>

            <Footer />
        </Container>
    )
}

export default ProductDisplay
