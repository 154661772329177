import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getProductImagesByProductId } from '../helpers/getData';
import { storage } from '../config/firebase';
import { ref } from 'firebase/storage';
import { deleteObject } from 'firebase/storage';
import axios from 'axios';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const Product = (props) => {
    const { productId, productName, price, salePrice, productImage } = props.data;
    const [additionalImages, setAdditionalImages] = useState([]);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const additionalImages = await getProductImagesByProductId(productId);
            setAdditionalImages(additionalImages);
        };
        fetchData();
    }, [productId]);

    const navigateToProductDisplay = () => {
        navigate(`/products/${productName}`, { state: { productId: `${productId}` } });
    }

    const editProductDetails = () => {
        navigate('/editproductdetails', { state: { productId: `${productId}` } });
    }

    const deleteProduct = async () => {
        try {
            const productImageRef = ref(storage, productImage);
            await deleteObject(productImageRef);

            for (const image of additionalImages) {
                const additionalImageRef = ref(storage, image.image);
                await deleteObject(additionalImageRef);
            }

            const response = await axios.post('https://artparadise.madushiranasooriyaportfolio.com/api/deleteProduct.php', { productId: productId });

            if (response.data.success) {
                alert("Product deleted successfully!");
                window.location.reload();
            }
            else {
                alert('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
            alert('An error occurred while deleting the product. Please try again.');
        } finally {
            setShowDeleteConfirmModal(false);
        }
    }

    const handleShowDeleteConfirmModal = () => setShowDeleteConfirmModal(true);
    const handleCloseDeleteConfirmModal = () => setShowDeleteConfirmModal(false);
    const handleConfirmDelete = () => deleteProduct();

    return (
        <div className='image-group'>
            <img src={productImage} alt={productName} onClick={navigateToProductDisplay} />
            <h5>{productName}</h5>
            {salePrice ?
                <p><span className='sale'>Save Rs.{price - salePrice} </span>&nbsp;&nbsp;&nbsp;Rs.<del>{price}</del> {salePrice}</p>
                : <p>Rs.{price}</p>
            }
            <button className='edit-button' onClick={editProductDetails}>
                <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <button className='remove-button' onClick={handleShowDeleteConfirmModal}>
                <FontAwesomeIcon icon={faTrash} />
            </button>

            <ConfirmDeleteModal show={showDeleteConfirmModal} handleClose={handleCloseDeleteConfirmModal}
                handleConfirm={handleConfirmDelete} />
        </div>
    )
}

export default Product
