// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDAjJV9klRNvNP_Dptl6smYN0vUoLY-MRo",
  authDomain: "art-paradise.firebaseapp.com",
  projectId: "art-paradise",
  storageBucket: "art-paradise.appspot.com",
  messagingSenderId: "242392000552",
  appId: "1:242392000552:web:4c9402611c9217ea106d1d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);